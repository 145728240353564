@use '@/styles/utils/mixins.scss' as *;

.btn.ant-btn {
  outline: none;
  position: relative;
  touch-action: manipulation;
  padding: 1rem 1.5rem;
  display: inline-flex;
  gap: 0.25rem;
  align-items: center;
  justify-content: center;
  border-radius: 0.5rem;
  min-width: 17.5rem;
  font-family: 'Fakt Pro', sans-serif;
  font-size: 1rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 0.002rem;
  text-align: center;
  text-decoration: none;
  vertical-align: middle;
  cursor: pointer;
  white-space: normal;
  user-select: none;
  border: 0.063rem solid transparent;
  background: transparent;
  transition: all 0.2s ease-in-out;
  height: auto;
  -webkit-box-shadow: 0rem 0rem 0rem 0rem rgba($primary, 0);
  -moz-box-shadow: 0rem 0rem 0rem 0rem rgba($primary, 0);
  box-shadow: 0rem 0rem 0rem 0rem rgba($primary, 0);

  span {
    margin-bottom: -0.188rem;
  }

  &:focus,
  &:focus-visible {
    outline: 0;
  }

  @include mob() {
    min-width: 100%;
  }

  &.fitWidth {
    min-width: fit-content;
  }
}

.fullWidth {
  width: 100%;
}

$button-colors: (
  'primary': (
    solid-background: $primary,
    solid-border: $primary,
    solid-hover: $primaryVariant,
    solid-text-color: $white,
    // Contrast ratio maintained
    outline-background: transparent,
    outline-border: $primary,
    outline-hover-background: $neutral03,
    outline-hover-border: $primary,
    outline-text-color: $dark,
    // Sufficient contrast against neutral03
  ),
  'primaryAlt': (
    solid-background: $primaryAlt,
    solid-border: $primaryAlt,
    solid-hover: darken($primaryAlt, 2.5%),
    solid-text-color: $white,
    // White text on primaryAlt background
    outline-background: transparent,
    outline-border: $primaryAlt,
    // PrimaryAlt outline for contrast
    outline-hover-background: $dark5,
    outline-hover-border: $primaryAlt,
    outline-text-color: $dark,
    // Sufficient contrast against dark5
  ),
  'primaryVariant': (
    solid-background: $primaryVariant,
    solid-border: $primaryVariant,
    solid-hover: darken($primaryVariant, 2.5%),
    solid-text-color: $white,
    // Contrast ratio maintained
    outline-background: transparent,
    outline-border: $primaryVariant,
    outline-hover-background: $neutral03,
    outline-hover-border: $primaryVariant,
    outline-text-color: $dark,
    // Sufficient contrast against neutral03
  ),
  'primaryAltVariant': (
    solid-background: $primaryAltVariant,
    solid-border: $primaryAltVariant,
    solid-hover: darken($primaryAltVariant, 2.5%),
    solid-text-color: $white,
    // Contrast ratio maintained
    outline-background: transparent,
    outline-border: $primaryAltVariant,
    outline-hover-background: $neutral03,
    outline-hover-border: $primaryAltVariant,
    outline-text-color: $dark,
    // Sufficient contrast against neutral03
  ),
  'secondary': (
    solid-background: $secondary,
    solid-border: $secondary,
    solid-hover: darken($secondary, 2.5%),
    solid-text-color: $dark,
    // Sufficient contrast against $secondary
    outline-background: transparent,
    outline-border: $secondary,
    outline-hover-background: $neutral03,
    outline-hover-border: $secondary,
    outline-text-color: $dark,
    // Sufficient contrast against neutral03
  ),
  'secondaryAlt': (
    solid-background: $secondaryAlt,
    solid-border: $secondaryAlt,
    solid-hover: darken($secondaryAlt, 2.5%),
    solid-text-color: $dark,
    // Sufficient contrast against $secondaryAlt
    outline-background: transparent,
    outline-border: $secondaryAlt,
    outline-hover-background: $neutral03,
    outline-hover-border: $secondaryAlt,
    outline-text-color: $dark,
    // Sufficient contrast against neutral03
  ),
  'dune': (
    solid-background: $dune,
    solid-border: $dune,
    solid-hover: darken($dune, 2.5%),
    solid-text-color: $white,
    // Contrast ratio maintained
    outline-background: transparent,
    outline-border: $dune,
    outline-hover-background: $neutral03,
    outline-hover-border: $dune,
    outline-text-color: $dark,
    // Sufficient contrast against neutral03
  ),
  'bean': (
    solid-background: $bean,
    solid-border: $bean,
    solid-hover: darken($bean, 2.5%),
    solid-text-color: $white,
    // Contrast ratio maintained
    outline-background: transparent,
    outline-border: $bean,
    outline-hover-background: $neutral03,
    outline-hover-border: $bean,
    outline-text-color: $dark,
    // Sufficient contrast against neutral03
  ),
  'moss': (
    solid-background: $moss,
    solid-border: $moss,
    solid-hover: darken($moss, 2.5%),
    solid-text-color: $white,
    // Contrast ratio maintained
    outline-background: transparent,
    outline-border: $moss,
    outline-hover-background: $neutral03,
    outline-hover-border: $moss,
    outline-text-color: $dark,
    // Sufficient contrast against neutral03
  ),
  'sky': (
    solid-background: $sky,
    solid-border: $sky,
    solid-hover: darken($sky, 2.5%),
    solid-text-color: $white,
    // Contrast ratio maintained
    outline-background: transparent,
    outline-border: $sky,
    outline-hover-background: $neutral03,
    outline-hover-border: $sky,
    outline-text-color: $dark,
    // Sufficient contrast against neutral03
  ),
  'error': (
    solid-background: $error,
    solid-border: $error,
    solid-hover: darken($error, 2.5%),
    solid-text-color: $white,
    // Contrast ratio maintained
    outline-background: transparent,
    outline-border: $error,
    outline-hover-background: $neutral03,
    outline-hover-border: $error,
    outline-text-color: $dark,
    // Sufficient contrast against neutral03
  ),
  'error20': (
    solid-background: $error20,
    solid-border: $error20,
    solid-hover: darken($error20, 2.5%),
    solid-text-color: $dark,
    // Sufficient contrast against $error20
    outline-background: transparent,
    outline-border: $error20,
    outline-hover-background: $neutral03,
    outline-hover-border: $error20,
    outline-text-color: $dark,
    // Sufficient contrast against neutral03
  ),
  'neutral01': (
    solid-background: $neutral01,
    solid-border: $neutral01,
    solid-hover: darken($neutral01, 2.5%),
    solid-text-color: $dark,
    // Sufficient contrast against $neutral01
    outline-background: transparent,
    outline-border: $neutral01,
    outline-hover-background: $neutral03,
    outline-hover-border: $neutral01,
    outline-text-color: $dark,
    // Sufficient contrast against neutral03
  ),
  'neutral02': (
    solid-background: $neutral02,
    solid-border: $neutral02,
    solid-hover: darken($neutral02, 2.5%),
    solid-text-color: $dark,
    // Sufficient contrast against $neutral02
    outline-background: transparent,
    outline-border: $neutral02,
    outline-hover-background: $neutral03,
    outline-hover-border: $neutral02,
    outline-text-color: $dark,
    // Sufficient contrast against neutral03
  ),
  'neutral03': (
    solid-background: $neutral03,
    solid-border: $neutral03,
    solid-hover: darken($neutral03, 2.5%),
    solid-text-color: $dark,
    // Sufficient contrast against $neutral03
    outline-background: transparent,
    outline-border: $neutral03,
    outline-hover-background: $neutral03,
    outline-hover-border: $neutral03,
    outline-text-color: $dark,
    // Sufficient contrast against neutral03
  ),
  'neutral04': (
    solid-background: $neutral04,
    solid-border: $neutral04,
    solid-hover: darken($neutral04, 2.5%),
    solid-text-color: $dark,
    // Sufficient contrast against $neutral04
    outline-background: transparent,
    outline-border: $neutral04,
    outline-hover-background: $neutral03,
    outline-hover-border: $neutral04,
    outline-text-color: $dark,
    // Sufficient contrast against neutral03
  ),
  'neutral05': (
    solid-background: $neutral05,
    solid-border: $neutral05,
    solid-hover: darken($neutral05, 2.5%),
    solid-text-color: $dark,
    // Sufficient contrast against $neutral05
    outline-background: transparent,
    outline-border: $neutral05,
    outline-hover-background: $neutral03,
    outline-hover-border: $neutral05,
    outline-text-color: $dark,
    // Sufficient contrast against neutral03
  ),
  'neutral06': (
    solid-background: $neutral06,
    solid-border: $neutral06,
    solid-hover: darken($neutral06, 2.5%),
    solid-text-color: $dark,
    // Sufficient contrast against $neutral06
    outline-background: transparent,
    outline-border: $neutral06,
    outline-hover-background: $neutral03,
    outline-hover-border: $neutral06,
    outline-text-color: $dark,
    // Sufficient contrast against neutral03
  ),
  'neutral07': (
    solid-background: $neutral07,
    solid-border: $neutral07,
    solid-hover: darken($neutral07, 2.5%),
    solid-text-color: $dark,
    // Sufficient contrast against $neutral07
    outline-background: transparent,
    outline-border: $neutral07,
    outline-hover-background: $neutral03,
    outline-hover-border: $neutral07,
    outline-text-color: $dark,
    // Sufficient contrast against neutral03
  ),
  'neutral08': (
    solid-background: $neutral08,
    solid-border: $neutral08,
    solid-hover: darken($neutral08, 2.5%),
    solid-text-color: $dark,
    // Sufficient contrast against $neutral08
    outline-background: transparent,
    outline-border: $neutral08,
    outline-hover-background: $neutral03,
    outline-hover-border: $neutral08,
    outline-text-color: $dark,
    // Sufficient contrast against neutral03
  ),
  'white': (
    solid-background: $white,
    solid-border: $white,
    solid-hover: $white80,
    solid-text-color: $dark,
    // Dark text on white background
    outline-background: transparent,
    outline-border: $dark,
    // Dark outline for contrast
    outline-hover-background: $dark5,
    outline-hover-border: $dark,
    outline-text-color: $dark,
    // Sufficient contrast against dark5
  ),
  'black': (
    solid-background: $black,
    solid-border: $black,
    solid-hover: darken($black, 2.5%),
    solid-text-color: $white,
    // White text on black background
    outline-background: transparent,
    outline-border: $white,
    outline-hover-background: $dark30,
    outline-hover-border: $white,
    outline-text-color: $white,
    // Sufficient contrast against dark30
  ),
  'white50': (
    solid-background: $white50,
    solid-border: $white50,
    solid-hover: $white80,
    solid-text-color: $dark,
    // Dark text on semi-transparent white background
    outline-background: transparent,
    outline-border: $dark,
    // Dark outline for contrast
    outline-hover-background: $dark5,
    outline-hover-border: $dark,
    outline-text-color: $dark,
    // Sufficient contrast against dark5
  ),
  'white80': (
    solid-background: $white80,
    solid-border: $white80,
    solid-hover: darken($white80, 2.5%),
    solid-text-color: $dark,
    // Dark text on semi-transparent white background
    outline-background: transparent,
    outline-border: $dark,
    // Dark outline for contrast
    outline-hover-background: $dark5,
    outline-hover-border: $dark,
    outline-text-color: $dark,
    // Sufficient contrast against dark5
  ),
  'dark': (
    solid-background: $dark,
    solid-border: $dark,
    solid-hover: darken($dark, 2.5%),
    solid-text-color: $white,
    // White text on dark background
    outline-background: transparent,
    outline-border: $white,
    outline-hover-background: $dark30,
    outline-hover-border: $white,
    outline-text-color: $white,
    // Sufficient contrast against dark30
  ),
  'dark5': (
    solid-background: $dark5,
    solid-border: $dark5,
    solid-hover: darken($dark5, 2.5%),
    solid-text-color: $dark,
    // Dark text on very light dark background
    outline-background: transparent,
    outline-border: $dark,
    // Dark outline for contrast
    outline-hover-background: $dark30,
    outline-hover-border: $dark,
    outline-text-color: $dark,
    // Sufficient contrast against dark30
  ),
  'dark30': (
    solid-background: $dark30,
    solid-border: $dark30,
    solid-hover: darken($dark30, 2.5%),
    solid-text-color: $white,
    // White text on semi-transparent dark background
    outline-background: transparent,
    outline-border: $white,
    outline-hover-background: $dark5,
    outline-hover-border: $white,
    outline-text-color: $white,
    // Sufficient contrast against dark5
  ),
);

// Function to capitalize the first letter
@function ucfirst($string) {
  @return to-upper-case(str-slice($string, 1, 1)) + str-slice($string, 2);
}

// Solid button mixin
@mixin solid-button-style($name, $background, $border, $hover, $text-color) {
  .btn.ant-btn.btn#{$name} {
    background: $background;
    background: linear-gradient(90deg, $background 0%, $background 0%);
    border-color: $border;
    color: $text-color;

    &:hover,
    &:not(:disabled):not(.ant-btn-disabled):hover {
      background: $hover;
      background: linear-gradient(90deg, $hover 0%, $hover 0%);
      border-color: $hover;
      color: $text-color;
    }

    &:focus,
    &:focus-visible,
    &:focus-within {
      color: $text-color;
      background: $background;
      background: linear-gradient(90deg, $background 0%, $background 0%);
      -webkit-box-shadow: 0rem 0rem 0rem 0.25rem rgba($border, 0.5);
      -moz-box-shadow: 0rem 0rem 0rem 0.25rem rgba($border, 0.5);
      box-shadow: 0rem 0rem 0rem 0.25rem rgba($border, 0.5);
      outline: 0;
    }

    &:active,
    &.active,
    &:visited,
    &:not(:disabled):not(.ant-btn-disabled):active {
      color: $text-color;
      border-color: $hover;
      background: $hover;
      background: linear-gradient(
        90deg,
        $background 0%,
        lighten($background, 10%) 100%
      );
    }

    &.disabled,
    &:disabled {
      background: $neutral05;
      border-color: $neutral05;
      color: inherit;
      pointer-events: none;
    }
  }
}

// Outline button mixin
@mixin outline-button-style(
  $name,
  $background,
  $border,
  $hover-bg,
  $hover-border,
  $text-color
) {
  .btn.ant-btn.btn#{$name}Outline {
    background: $background;
    border-color: $border;
    color: $text-color;

    &:hover,
    &:not(:disabled):not(.ant-btn-disabled):hover {
      background: $hover-bg;
      border-color: $hover-border;
      color: $text-color;
    }

    &:focus,
    &:focus-visible,
    &:focus-within {
      color: $text-color;
      -webkit-box-shadow: 0rem 0rem 0rem 0.25rem rgba($border, 0.5);
      -moz-box-shadow: 0rem 0rem 0rem 0.25rem rgba($border, 0.5);
      box-shadow: 0rem 0rem 0rem 0.25rem rgba($border, 0.5);
      outline: 0;
    }

    &:active,
    &.active,
    &:visited,
    &:not(:disabled):not(.ant-btn-disabled):active {
      background: $hover-bg;
      border-color: $hover-border;
      color: $text-color;
    }

    &.disabled,
    &:disabled {
      background: $neutral05;
      border-color: $neutral05;
      color: inherit;
      pointer-events: none;
    }
  }
}

// Loop to generate button styles for each color and variant
@each $name, $colors in $button-colors {
  // Solid variant
  @include solid-button-style(
    ucfirst($name),
    map-get($colors, solid-background),
    map-get($colors, solid-border),
    map-get($colors, solid-hover),
    map-get($colors, solid-text-color)
  );

  // Outline variant
  @include outline-button-style(
    ucfirst($name),
    map-get($colors, outline-background),
    map-get($colors, outline-border),
    map-get($colors, outline-hover-background),
    map-get($colors, outline-hover-border),
    map-get($colors, outline-text-color)
  );
}

.btn.ant-btn-default {
  background: inherit;
  border-color: inherit;
  color: inherit;
  box-shadow: none;
}
.btn.ant-btn-default:not(:disabled):not(.ant-btn-disabled):hover {
  background: inherit;
  border-color: inherit;
  color: inherit;
}

.btn.ant-btn.btnPrimary:hover,
.btn.ant-btn.btnPrimary:not(:disabled):not(.ant-btn-disabled):hover {
  border-color: $primaryAlt;
  -webkit-box-shadow: 0rem 0.375rem 1rem 0rem rgba($primaryAlt, 0.35);
  -moz-box-shadow: 0rem 0.375rem 1rem 0rem rgba($primaryAlt, 0.35);
  box-shadow: 0rem 0.375rem 1rem 0rem rgba($primaryAlt, 0.35);
}
